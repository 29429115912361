
  import { defineComponent, computed, ref, toRefs, PropType, reactive } from 'vue';

  import AppIcon from '@/components/stateless/AppIcon.vue';
  import Compute from '@/components/hoc/Compute.vue';
  import AppCheckbox from '@/components/stateless/AppCheckbox.vue';

  import { TIndexedObject, IAppInputError } from '@/types';
  import { onClickOutside } from '@/hooks';

  type TSelectOption = TIndexedObject<string | number>
  type TSelectSizes = 'sm' | 'md'

  export default defineComponent({
    name: 'AppSelectSecondary',

    components: { AppIcon, Compute, AppCheckbox },

    props: {
      modelValue: {
        type: [Object, Array] as PropType<TSelectOption | Array<string | number>>,
        required: true
      },

      placeholder: {
        type: String as PropType<string>,
        default: 'Select options'
      },

      options: {
        type: Array as PropType<Array<TSelectOption>>,
        required: true
      },

      size: {
        type: String as PropType<TSelectSizes>,
        default: 'md'
      },

      itemKey: {
        type: String as PropType<string>,
        default: 'value'
      },

      itemLabel: {
        type: String as PropType<string>,
        default: 'label'
      },

      disabledOptions: {
        type: Array as PropType<Array<string | number>>,
        default: null
      },

      multiple: {
        type: Boolean as PropType<boolean>,
        default: false
      },

      disabled: {
        type: Boolean as PropType<boolean>,
        default: false
      },

      error: {
        type: [String, Object as () => IAppInputError],
        default: ''
      },
    },

    emits: ['update:modelValue', 'change'],

    setup(props, { emit }) {
      const {
        modelValue,
        size,
        options,
        multiple,
        itemKey,
        itemLabel,
        disabledOptions,
        disabled
      } = toRefs(props);
      const isOpen = ref<boolean>(false);

      const hasInitialValue = computed(() => {
        if (Array.isArray(modelValue.value)) {
          return modelValue.value.length > 0;
        } else {
          return Object.entries(modelValue.value).length > 0;
        }
      });

      const selectedValue = computed<string>(() => {
        if (!hasInitialValue.value) {
          return '';
        }

        if (multiple.value) {
          return options.value
            .filter(i => (modelValue.value as Array<string | number>).includes(i[itemKey.value]))
            .map(i => i[itemLabel.value]).join(',');
        } else {
          return (modelValue.value as TSelectOption)[itemLabel.value] as string;
        }
      });

      const sizes = computed<string>(() => {
        return `size-${size.value}`;
      });

      const err = computed<IAppInputError>(() => { 
      if (typeof props.error === 'string') {

        return reactive<IAppInputError>({ message: props.error, link: { name: '', message: '' } });
      }

      return props.error;
    });

      // Controls
      function open() {
        if (disabled.value) return;
        isOpen.value = true;
      }

      function close() {
        if (disabled.value) return;
        isOpen.value = false;
      }

      function toggle() {
        if (disabled.value) return;
        isOpen.value = !isOpen.value;
      }

      function handleMultipleSelection(option: TSelectOption) {
        const value = option[itemKey.value];
        const set: Set<string | number | TSelectOption> = new Set(modelValue.value as Array<string | TSelectOption>);

        set.has(value) ? set.delete(value) : set.add(value);

        return Array.from(set);
      }

      function setSelection(option: TSelectOption) {
        const toEmit = multiple.value ? handleMultipleSelection(option) : option;
        emit('update:modelValue', toEmit);
        emit('change', toEmit);

        if (!multiple.value) {
          close();
        }
      }

      // States
      function isSelected(option: TSelectOption) {
        if (!hasInitialValue.value) {
          return false;
        }

        if (multiple.value) {
          return (modelValue.value as Array<string | number>).some(i => i === option[itemKey.value]);
        } else {
          return (modelValue.value as TSelectOption)[itemKey.value] === option[itemKey.value];
        }
      }

      function isDisabled(option: TSelectOption) {
        return disabledOptions.value ? disabledOptions.value.includes(option[itemKey.value]) : false;
      }

      onClickOutside(close);

      return {
        hasInitialValue,
        isOpen,
        err,

        open,
        close,
        toggle,

        selectedValue,
        rawValue: modelValue,
        setSelection,
        isSelected,
        isDisabled,

        sizes
      };
    }
  });
