<template>
  <div 
    class="flex items-center"
    :class="{ 'opacity-30': disabled }"
  >
    <div
      class="relative flex-shrink-0 h-19 w-19 border rounded-5"
      :class="modelValue ? 'border-success bg-success' : 'border-grey-fp-50'"
    >
      <input
        :id="name"
        class="absolute w-full h-full m-0 opacity-0 z-20"
        type="checkbox"
        :data-test="name"
        :checked="modelValue"
        :disabled="disabled"
        :class="customClass"
        @input="onInputHandler"
      >
      <div 
        :class="modelValue ? 'opacity-100' : 'opacity-0'" 
        class="flex items-center justify-center w-full h-full transition duration-300"
      >
        <AppIcon name="checkmark" size="12" class="text-white z-10" />
      </div>
    </div>
    <label class="leading-xs text-dark-cl-20 pl-10" :for="name" @click.stop>

      <!-- S L O T -->
      <slot />
    </label>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import AppIcon from '@/components/stateless/AppIcon.vue';

export default defineComponent({
  name: 'AppCheckbox',

  components: { AppIcon },

  props: {
  
    modelValue: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    customClass: {
      type: String,
      default: ''
    },

    name: {
      type: String,
      default: 'checkbox'
    },
  },

  emits: ['update:modelValue', 'click'],

  setup (props, { emit }) {

    function onInputHandler (e: InputEvent) {
      if (props.disabled) { return; }

      const target = e.target as HTMLInputElement;

      emit('update:modelValue', target.checked);
      emit('click', target.checked);
    }

    return {
      onInputHandler
    };
  }
});
</script>
