<template>
  <!-- eslint-disable vue/no-template-shadow -->
  <div class="select__wrap">
    <v-dropdown
      :triggers="[]"
      :shown="isOpen"
      :autoHide="false"
      container=".select__wrap"
      :offset="[0, 10]"
      placement="bottom"
    >
      <div
        class="border border-grey-fp-50 rounded"
        :class="[{'is-disabled': disabled, 'border-red-fp-30': err.message}, sizes]"
        tabindex="0"
      >
        <div
          data-test="preview"
          class="select__preview"
          @click="toggle"
        >
          <span class="select__value text-base mr-20 text-dark-cl-20">
            <slot
              name="selection"
              :value="rawValue"
              :hasInitialValue="hasInitialValue"
            >
              {{ selectedValue || placeholder }}
            </slot>
          </span>

          <AppIcon
            name="arrow-close"
            size="24"
            class="select__icon transform"
            :class="isOpen ? 'rotate-180' : 'rotate-0'"
          />
        </div>
      </div>
        
      <template #popper>
        <ul
          data-test="options-list"
          class="select__options overflow-y-auto py-5 max-h-200 custom-scrollbar bg-white w-full"
        >
          <template
            v-for="item in options"
            :key="item.value"
          >
            <Compute
              #default="{ isSelected, isDisabled }"
              :isSelected="isSelected(item)"
              :isDisabled="isDisabled(item)"
            >
              <li
                data-test="option"
                class="select__options-item hover:bg-blue-fp-40"
                :class="{
                  'is-selected': isSelected,
                  'is-disabled': isDisabled
                }"
                @click="isDisabled ? null : setSelection(item)"
              >
                <div class="flex items-center">
                  <AppCheckbox v-if="multiple" :modelValue="isSelected" class="cursor-pointer" />
                  <slot
                    name="options"
                    :item="item"
                    :is-selected="isSelected"
                    :is-disabled="isDisabled"
                  >
                    {{ item[itemLabel] }}
                  </slot>
                </div>
              </li>
            </Compute>
          </template>
        </ul>
      </template>
    </v-dropdown>

    <!-- E R R O R -->
    <p
      v-if="error"
      class="text-red-fp-30 absolute text-sm sm:text-base top-full left-0 mt-2 flex items-center"
    >
      <span class="mr-3">{{ error }}</span>
    </p>
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed, ref, toRefs, PropType, reactive } from 'vue';

  import AppIcon from '@/components/stateless/AppIcon.vue';
  import Compute from '@/components/hoc/Compute.vue';
  import AppCheckbox from '@/components/stateless/AppCheckbox.vue';

  import { TIndexedObject, IAppInputError } from '@/types';
  import { onClickOutside } from '@/hooks';

  type TSelectOption = TIndexedObject<string | number>
  type TSelectSizes = 'sm' | 'md'

  export default defineComponent({
    name: 'AppSelectSecondary',

    components: { AppIcon, Compute, AppCheckbox },

    props: {
      modelValue: {
        type: [Object, Array] as PropType<TSelectOption | Array<string | number>>,
        required: true
      },

      placeholder: {
        type: String as PropType<string>,
        default: 'Select options'
      },

      options: {
        type: Array as PropType<Array<TSelectOption>>,
        required: true
      },

      size: {
        type: String as PropType<TSelectSizes>,
        default: 'md'
      },

      itemKey: {
        type: String as PropType<string>,
        default: 'value'
      },

      itemLabel: {
        type: String as PropType<string>,
        default: 'label'
      },

      disabledOptions: {
        type: Array as PropType<Array<string | number>>,
        default: null
      },

      multiple: {
        type: Boolean as PropType<boolean>,
        default: false
      },

      disabled: {
        type: Boolean as PropType<boolean>,
        default: false
      },

      error: {
        type: [String, Object as () => IAppInputError],
        default: ''
      },
    },

    emits: ['update:modelValue', 'change'],

    setup(props, { emit }) {
      const {
        modelValue,
        size,
        options,
        multiple,
        itemKey,
        itemLabel,
        disabledOptions,
        disabled
      } = toRefs(props);
      const isOpen = ref<boolean>(false);

      const hasInitialValue = computed(() => {
        if (Array.isArray(modelValue.value)) {
          return modelValue.value.length > 0;
        } else {
          return Object.entries(modelValue.value).length > 0;
        }
      });

      const selectedValue = computed<string>(() => {
        if (!hasInitialValue.value) {
          return '';
        }

        if (multiple.value) {
          return options.value
            .filter(i => (modelValue.value as Array<string | number>).includes(i[itemKey.value]))
            .map(i => i[itemLabel.value]).join(',');
        } else {
          return (modelValue.value as TSelectOption)[itemLabel.value] as string;
        }
      });

      const sizes = computed<string>(() => {
        return `size-${size.value}`;
      });

      const err = computed<IAppInputError>(() => { 
      if (typeof props.error === 'string') {

        return reactive<IAppInputError>({ message: props.error, link: { name: '', message: '' } });
      }

      return props.error;
    });

      // Controls
      function open() {
        if (disabled.value) return;
        isOpen.value = true;
      }

      function close() {
        if (disabled.value) return;
        isOpen.value = false;
      }

      function toggle() {
        if (disabled.value) return;
        isOpen.value = !isOpen.value;
      }

      function handleMultipleSelection(option: TSelectOption) {
        const value = option[itemKey.value];
        const set: Set<string | number | TSelectOption> = new Set(modelValue.value as Array<string | TSelectOption>);

        set.has(value) ? set.delete(value) : set.add(value);

        return Array.from(set);
      }

      function setSelection(option: TSelectOption) {
        const toEmit = multiple.value ? handleMultipleSelection(option) : option;
        emit('update:modelValue', toEmit);
        emit('change', toEmit);

        if (!multiple.value) {
          close();
        }
      }

      // States
      function isSelected(option: TSelectOption) {
        if (!hasInitialValue.value) {
          return false;
        }

        if (multiple.value) {
          return (modelValue.value as Array<string | number>).some(i => i === option[itemKey.value]);
        } else {
          return (modelValue.value as TSelectOption)[itemKey.value] === option[itemKey.value];
        }
      }

      function isDisabled(option: TSelectOption) {
        return disabledOptions.value ? disabledOptions.value.includes(option[itemKey.value]) : false;
      }

      onClickOutside(close);

      return {
        hasInitialValue,
        isOpen,
        err,

        open,
        close,
        toggle,

        selectedValue,
        rawValue: modelValue,
        setSelection,
        isSelected,
        isDisabled,

        sizes
      };
    }
  });
</script>

<style lang="scss" scoped>
  .select {
    &__wrap {
      @apply relative flex flex-col items-center rounded-5 outline-none cursor-pointer;

      &.size-sm {
        @apply text-base;
        height: 26px;

        .select {
          &__options {
            @apply rounded-5;
          }

          &__options-item {
            @apply px-18 py-2 text-base;
          }
        }
      }

      &.size-md {
        @apply text-md;
        height: 44px;

        .select {
          &__options {
            @apply rounded-5 shadow-1;
          }

          &__options-item {
            @apply px-15 py-5 text-base;
          }
        }
      }

      // &:not(.is-disabled) {
      // }

      &.is-disabled {
        @apply cursor-not-allowed opacity-50;
      }
    }

    &__preview {
      @apply flex flex-grow items-center w-full pl-15 pr-10 py-5;
    }

    &__value {
      @apply flex-grow truncate;
    }

    &__icon {
      @apply transition duration-300;
    }

    &__options-item {
      @apply
        py-8 px-15
        cursor-pointer
        transition duration-300;

      &.is-selected {
        @apply bg-blue-fp-40;
      }

      &.is-disabled {
        @apply opacity-50 cursor-not-allowed;
      }

      // &:not(.is-disabled) {
      // }
    }
  }
</style>
