
import { defineComponent } from 'vue';

import AppIcon from '@/components/stateless/AppIcon.vue';

export default defineComponent({
  name: 'AppCheckbox',

  components: { AppIcon },

  props: {
  
    modelValue: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    customClass: {
      type: String,
      default: ''
    },

    name: {
      type: String,
      default: 'checkbox'
    },
  },

  emits: ['update:modelValue', 'click'],

  setup (props, { emit }) {

    function onInputHandler (e: InputEvent) {
      if (props.disabled) { return; }

      const target = e.target as HTMLInputElement;

      emit('update:modelValue', target.checked);
      emit('click', target.checked);
    }

    return {
      onInputHandler
    };
  }
});
